<template>
  <div>
    <v-input
      title="建筑名称"
      placeholder="请输入建筑名称"
      ref="keyword"
      v-model="inputValue"
      :solid="false"
      align="right"
      rules="required"
    ></v-input>
    <div ref="mapContainer" class="map-container" style="display:none"></div>
  </div>
</template>

<script>
import AMapMixin from '@/mixins/aMapMixin'

export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  mixins: [AMapMixin],
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      },
    },
    markers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      inputValue: '',
      map: null,
      marker: null,
      infoWindow: null,
    }
  },
  methods: {
    initMap() {
      const AMapUI = (this.AMapUI = window.AMapUI)
      const AMap = (this.AMap = window.AMap)
      this.map = new AMap.Map(this.$refs.mapContainer, {
        resizeEnable: true,
        zoom: 14, // 地图显示的缩放级别
        keyboardEnable: false,
      })
      const _this = this
      AMap.plugin('AMap.Geolocation', function() {
        const geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
          // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
          buttonOffset: new AMap.Pixel(10, 20),
          //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          zoomToAccuracy: false,
          //  定位按钮的排放位置,  RB表示右下
          buttonPosition: 'RB',
        })

        _this.map.addControl(geolocation)
        geolocation.getCurrentPosition()
        // AMap.event.addListener(geolocation, 'complete', onComplete)
        AMap.event.addListener(geolocation, 'error', onError)

        // function onComplete(data) {
        //   // data是具体的定位信息
        //   console.log('complete', data)
        //   _this.$message.error('定位失败')
        // }

        function onError(data) {
          // 定位出错
          console.log('error', data)
          // _this.$toast.failed('定位失败')
        }
      })
      this.infoWindow = new AMap.InfoWindow({
        offset: new AMap.Pixel(0, -20),
      })
      AMapUI.loadUI(['misc/PoiPicker'], PoiPicker => {
        const poiPicker = new PoiPicker({
          input: this.$el.querySelector('.md-input-item-input'),
        })

        poiPicker.on('poiPicked', poiResult => {
          const poi = poiResult.item
          this.$emit('change', poi)
        })
      })
    },
    _setPoi(poi) {
      const { map, infoWindow } = this
      const AMap = (this.AMap = window.AMap)
      this.marker = new AMap.Marker()

      this.marker.setMap(map)
      this.marker.on('click', e => this.$emit('marker-click', e, poi))
      infoWindow.setMap(map)
      const { location } = poi
      if (location.lng && location.lat) {
        map.setCenter([location.lng, location.lat])
        this.marker.setPosition([location.lng, location.lat])
        infoWindow.setPosition([location.lng, location.lat])
        infoWindow.setContent(`<pre>${poi.name}</pre><pre>地址：${poi.district || ''}${poi.address}</pre>`)
        infoWindow.open(map, this.marker.getPosition())
      } else {
        map.remove(this.marker)
      }
    },
  },
  watch: {
    value: {
      deep: true,
      handler(newVal) {
        this.inputValue = newVal.name || ''
        if (Object.keys(newVal).length > 0) {
          this.$nextTick(() => this._setPoi(newVal))
        }
      },
    },
    markers: {
      deep: true,
      handler(newVal) {
        const AMap = (this.AMap = window.AMap)

        newVal.forEach(item => {
          // 创建一个 Marker 实例：
          const marker = new AMap.Marker({
            position: new AMap.LngLat(item.lng, item.lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: item.title,
          })
          marker.on('click', e => this.$emit('marker-click', e, item.poi))

          // 将创建的点标记添加到已有的地图实例：
          this.map.add(marker)
        })
      },
    },
  },
}
</script>

<style lang="stylus" scoped>
>>> .amap-marker-content
  div
    line-height normal
>>>.amap-geolocation-con
  z-index 99 !important
.map-container
  width 100%
  height 600px
  border 1px solid #d9d9d9
  border-radius 4px
  margin-top 24px
</style>
