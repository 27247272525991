<template>
  <div class="container">
    <nav-bar title="建筑评判"></nav-bar>
    <validation-observer ref="observer">
      <md-field>
        <place-search
          v-model="poi"
          @change="handlePoiChange"
          :markers="markers"
          @marker-click="handleMarkerClick"
          ref="firstInput"
        ></place-search>
        <v-input
          title="地上层数"
          placeholder="请输入地上层数"
          type="digit"
          v-model="c"
          :solid="false"
          align="right"
          rules="required|only-num|max_value:70|min_value:0"
        ></v-input>
        <v-input
          title="地下层数"
          placeholder="请输入地下层数"
          type="digit"
          v-model="c1"
          :solid="false"
          align="right"
          rules="required|only-num|max_value:5|min_value:0"
        ></v-input>
        <v-input
          title="高度 (m)"
          placeholder="自动计算，也可自行修改"
          type="money"
          v-model="h"
          :solid="false"
          align="right"
          rules="required|only-num|max_value:250|min_value:3"
        ></v-input>
        <v-input
          title="地下深度 (m)"
          placeholder="自动计算，也可自行修改"
          type="money"
          v-model="h1"
          :solid="false"
          align="right"
          rules="required|only-num|max_value:20|min_value:0"
        ></v-input>
        <v-input
          title="标准层面积 (㎡)"
          placeholder="请输入标准层面积"
          type="money"
          v-model="s"
          :solid="false"
          align="right"
          rules="required|only-num|min_value:200"
        ></v-input>
        <v-input
          title="建筑总面积 (㎡)"
          placeholder="自动估算，也可自行修改"
          type="money"
          v-model="s1"
          :solid="false"
          align="right"
          rules="required|only-num|min_value:200"
        ></v-input>
        <v-input
          title="体积 (m³)"
          placeholder="自动估算，也可自行修改"
          type="money"
          v-model="v"
          :solid="false"
          align="right"
          rules="required|only-num"
        ></v-input>
        <v-field
          title="建筑功能"
          placeholder="请选择建筑功能"
          :content="type"
          solid
          arrow
          @click="tabPickerShow = true"
          rules="required"
          :value="type"
          class="building-type"
          align="right"
        ></v-field>
        <v-input
          title="座位数"
          placeholder="请输入座位数"
          type="digit"
          v-model="seatNum"
          :solid="false"
          align="right"
          rules="required|only-num|min_value:1"
          v-if="isCinema || isHall || isGym"
        ></v-input>
        <md-field-item
          v-if="
            isOffice ||
            isHotel ||
            isPTransport ||
            isHospital ||
            isComplex ||
            isEntertainment ||
            isFinancial ||
            isSchool ||
            isDispatch ||
            isPostal ||
            isTV ||
            isReserch ||
            isOldBuild ||
            isExhibit ||
            isArchive ||
            isMuseum ||
            isKid ||
            isWelfare ||
            isGym ||
            isShop
          "
        >
          <md-check-group v-model="meta">
            <md-check-box name="isParty" label="地市级及以上党政办公楼" class="margin-right-xs" v-if="isOffice" />
            <md-check-box name="hasCourtyard" label="该建筑有中庭" class="margin-right-xs" v-if="isOffice" />
            <md-check-box
              name="hasAir"
              label="有集中空调"
              class="margin-right-xs"
              v-if="isHotel || isFinancial || isOffice || isComplex || isDispatch || isPostal || isTV || isReserch"
            />
            <md-check-box name="has1500" label="人数大于1500" class="margin-right-xs" v-if="isPTransport || isFinancial" />
            <md-check-box name="hasBed150" label="床位大于150" class="margin-right-xs" v-if="isHospital || isOldBuild" />
            <md-check-box name="hasShop" label="商场面积大于15000㎡" class="margin-right-xs" v-if="isComplex" />
            <md-check-box name="hasEtmt1500" label="人数（座位）大于1500" class="margin-right-xs" v-if="isEntertainment" />
            <md-check-box name="provincial" label="省级及以上" class="margin-right-xs" v-if="isFinancial" />
            <md-check-box name="city" label="地市级及以上" class="margin-right-xs" v-if="isPostal || isMuseum || isArchive || isExhibit" />
            <md-check-box name="has500" label="中小学人数大于500" class="margin-right-xs" v-if="isSchool" />
            <md-check-box name="has200" label="人数大于200" class="margin-right-xs" v-if="isKid || isWelfare" />
            <md-check-box
              name="hasS1000"
              label="24m以上，任一层面积大于1000㎡"
              class="margin-right-xs"
              v-if="
                (isShop || isComplex || isFinancial || isPostal || isExhibit) && ((h && h > 24 && !customH) || (customH && customH > 24))
              "
            />
            <md-check-box name="indpt" label="独立建造" class="margin-right-xs" v-if="isOldBuild" />
            <md-check-box name="openAir" label="露天" class="margin-right-xs" v-if="isGym" />
          </md-check-group>
        </md-field-item>
        <md-field-item v-if="isHospital">
          <md-check-box name="ward" v-model="hospitalType" label="病房楼" class="margin-right-xs" />
          <md-check-box name="outpatient" v-model="hospitalType" label="门诊楼" class="margin-right-xs" />
        </md-field-item>
        <md-field-item v-if="isDispatch">
          <md-check-box name="provincial" v-model="dispatchType" label="省级/网局级及以上" class="margin-right-xs" />
          <md-check-box name="city" v-model="dispatchType" label="城市/区域级" class="margin-right-xs" />
        </md-field-item>
        <md-field-item v-if="isTV">
          <md-check-box name="provincial" v-model="TVType" label="省级及以上" class="margin-right-xs" />
          <md-check-box name="city" v-model="TVType" label="地市级及以上" class="margin-right-xs" />
        </md-field-item>
        <md-field-item v-if="isAncient">
          <md-check-box name="city" v-model="ancientLevel" label="地市级及以上" class="margin-right-xs" />
          <md-check-box name="country" v-model="ancientLevel" label="国家级" class="margin-right-xs" />
        </md-field-item>
        <md-field-item v-if="isBook">
          <md-check-box name="has50w" v-model="bookNum" label="藏书50万-100万册" class="margin-right-xs" />
          <md-check-box name="has100w" v-model="bookNum" label="藏书100万册以上" class="margin-right-xs" />
        </md-field-item>
        <md-field-item v-if="h <= 24 && h && c <= 5">
          <md-check-box name="level3" v-model="fireLevel" label="三级耐火等级" class="margin-right-xs" v-if="c <= 5" />
          <md-check-box name="level4" v-model="fireLevel" label="四级耐火等级" class="margin-right-xs" v-if="c <= 2" />
        </md-field-item>
      </md-field>
    </validation-observer>
    <md-tab-picker title="建筑功能" describe="请选择建筑功能" large-radius :data="data" v-model="tabPickerShow" @change="handleChange">
    </md-tab-picker>
    <md-action-bar :actions="[{ text: '提交', onClick: this.handleSubmit }]"></md-action-bar>
    <md-picker
      v-model="poiVisible"
      :data="existResult"
      is-cascade
      large-radius
      title="已有评判结果"
      @confirm="handleSelectResult"
    ></md-picker>
  </div>
</template>

<script>
import buildingType from '@/utils/buildingType'
import _ from 'lodash'
import PlaceSearch from '@/components/Amap/PlaceSearch'
import { list } from '@/api/judge'

export default {
  components: { PlaceSearch },
  data() {
    return {
      poi: {},
      s: '',
      c: '',
      c1: '',
      tabPickerShow: false,
      data: {},
      meta: [],
      fireLevel: '',
      level: 3,
      isOffice: false,
      isOldBuild: false,
      isHotel: false,
      isPTransport: false, // 客运建筑
      isShop: false,
      isHospital: false,
      isComplex: false, // 综合建筑
      isEntertainment: false, // 歌舞娱乐
      isFinancial: false, // 金融
      isSchool: false, // 教学建筑
      isHouse: false, // 住宅
      isDispatch: false, // 指挥调度，电力调度
      isPostal: false, // 邮政电信
      isTV: false, // 广电
      isReserch: false, // 科研
      isAncient: false, // 文物
      isExhibit: false, // 展览馆
      isMuseum: false, // 博物馆
      isArchive: false, // 档案馆
      isBook: false, // 图书馆
      isKid: false, // 幼儿园
      isWelfare: false, // 儿童福利院
      isDorm: false, // 宿舍
      isMarket: false, // 集市
      isDining: false, // 餐厅食堂
      isGym: false, // 体育馆
      isCinema: false, // 影剧院
      isHall: false, // 会堂
      hospitalType: '',
      dispatchType: '',
      ancientLevel: '', // 文物级别
      bookNum: '', // 藏书数量
      seatNum: '', // 座位数量
      TVType: '',
      typeIds: [],
      typeId: undefined,
      type: '',
      name: '',
      markers: [],
      clientId: undefined,
      poiVisible: false,
      existResult: [],
      isPro: this.$store.getters['user/isPro'],
      isSuperPro: this.$store.getters['user/isSuperPro'],
      isTest: this.$store.getters['user/isTest'],
      customH: '',
      customH1: '',
      customV: '',
      customS1: '',
    }
  },
  computed: {
    h: {
      get() {
        return this.c !== '' ? _.round(this.c * 3.9, 2) : ''
      },
      set(val) {
        this.customH = val != this.h ? val : ''
      },
    },
    h1: {
      get() {
        return this.c1 !== '' ? _.round(this.c1 * 3.9, 2) : ''
      },
      set(val) {
        this.customH1 = val != this.h1 ? val : ''
      },
    },
    v: {
      get() {
        return this.h !== '' && this.h1 !== '' && this.s !== ''
          ? _.round(_.multiply(_.add(Number(this.h), Number(this.h1)), this.s), 2)
          : ''
      },
      set(val) {
        this.customV = val != this.v ? val : ''
      },
    },
    s1: {
      get() {
        return this.c !== '' && this.c1 !== '' && this.s !== ''
          ? _.round(_.multiply(this.s, _.add(Number(this.c), Number(this.c1))), 2)
          : ''
      },
      set(val) {
        this.customS1 = val != this.s1 ? val : ''
      },
    },
  },
  created() {
    if (this.isPro) {
      this.typeIds = this.$store.getters['user/info'].invite_code.client.type_ids
      this.clientId = this.$store.getters['user/info'].invite_code.client.id
      list({ client_id: this.clientId }).then((res) => {
        const { data } = res
        data.forEach((item) => {
          const { poi } = item.body
          if (poi) {
            this.markers.push({ title: poi.name, lng: poi.location.lng, lat: poi.location.lat, poi })
          }
        })
      })
    } else {
      this.typeIds = [229, 208, 215, 219, 227, 210, 237, 238, 239, 240, 241, 230, 231, 232, 233, 234, 235, 236, 211, 212, 213, 214]
    }
    buildingType.options = this._initType(buildingType.options)
    this.data = buildingType
  },
  mounted() {
    this.$refs.firstInput.$refs.keyword.$refs.input.focus()
  },
  methods: {
    handlePoiChange(val) {
      this.name = val.name
    },
    handleMarkerClick(e, item) {
      if (this.isPro) {
        list({ client_id: this.clientId, location_id: item.id }).then((res) => {
          if (res.data.length > 0) {
            this.poiVisible = true
            this.existResult = [
              res.data.map((item) => ({
                value: item.id,
                text: `${item.body.name}/${item.user.name}提交`,
                body: item.body,
              })),
            ]
          }
        })
      }
    },
    handleChange({ options }) {
      this._setType(_.last(options).value, _.last(options).label)
    },
    handleSubmit() {
      this.$refs.observer.validate().then((result) => {
        if (!result) {
          return
        }

        // 集市 食堂 宿舍
        const isMDD = this.isMarket || this.isDorm || this.isDining

        // 幼儿园 儿童福利院
        const isKW = this.isKid || this.isWelfare

        const h = Number(this.customH) || Number(this.h)
        const s1 = Number(this.customS1) || Number(this.s1)

        const isOfficeParty = h > 24 && this.isOffice && _.includes(this.meta, 'isParty')
        const has1500 = h > 24 && this.isPTransport && _.includes(this.meta, 'has1500')
        const shopLevel1 = h > 24 && this.isShop && (s1 > 20000 || _.includes(this.meta, 'hasS1000'))
        const hasCShop = this.isComplex && _.includes(this.meta, 'hasShop')
        const hasEtmt1500 = this.isEntertainment && _.includes(this.meta, 'hasEtmt1500')

        if (this.isHouse) {
          if (h > 54) {
            this.level = 1
          } else if (h > 27 && h <= 54) {
            this.level = 2
          } else {
            this.level = 3
          }
        } else {
          if (
            (h > 50 ||
              isOfficeParty ||
              (h > 24 && this.isOldBuild && (_.includes(this.meta, 'indpt') || _.includes(this.meta, 'hasBed150'))) ||
              has1500 ||
              shopLevel1 ||
              (h > 24 && this.isHospital) ||
              (this.isComplex && h > 24 && (_.includes(this.meta, 'hasS1000') || _.includes(this.meta, 'hasShop'))) ||
              (hasEtmt1500 && h > 24) ||
              (this.isFinancial &&
                h > 24 &&
                (_.includes(this.meta, 'provincial') || _.includes(this.meta, 'hasS1000') || _.includes(this.meta, 'has1500'))) ||
              (this.isSchool && h > 24 && _.includes(this.meta, 'has500')) ||
              (this.isDispatch && h > 24 && this.dispatchType === 'provincial') ||
              (this.isPostal && h > 24 && _.includes(this.meta, 'hasS1000')) ||
              (this.isTV && h > 24 && this.TVType === 'provincial') ||
              ((this.isMuseum || this.isArchive) && h > 24 && _.includes(this.meta, 'city')) ||
              (this.isBook && h > 24 && this.bookNum) ||
              (this.isExhibit && h > 24 && (_.includes(this.meta, 'hasS1000') || _.includes(this.meta, 'city'))) ||
              (this.isAncient && h > 24 && this.ancientLevel) ||
              (this.isKid && h > 24 && _.includes(this.meta, 'has200')) ||
              ((this.isCinema || this.isHall || this.isGym) && h > 24 && this.seatNum > 1500)) &&
            this.c > 1
          ) {
            this.level = 1
          } else if (h > 24 && h <= 50 && this.c > 1) {
            this.level = 2
          } else {
            this.level = 3
          }
        }

        // 是否设置自喷
        const noSpray =
          (this.isHouse && h <= 100) ||
          (this.isShop && this.level === 3 && this.s <= 1500 && s1 <= 3000 && this.s * this.c1 <= 500) ||
          ((this.isHotel || this.isHospital || this.isPTransport || isMDD || this.isMuseum || this.isExhibit) &&
            this.level === 3 &&
            this.s <= 1500 &&
            s1 <= 3000) ||
          (this.isEntertainment && this.level === 3 && this.c < 4 && this.c1 < 1 && this.s <= 300) ||
          ((this.isOffice || this.isComplex || this.isDispatch || this.isFinancial || this.isPostal || this.isTV || this.isReserch) &&
            this.level === 3 &&
            (!_.includes(this.meta, 'hasAir') || s1 <= 3000)) ||
          ((this.isSchool || this.isArchive) && this.level === 3 && s1 <= 3000) ||
          (this.isBook && this.level === 3 && !this.bookNum) ||
          (this.isAncient && this.level === 3) ||
          (isKW && this.level === 3 && !_.includes(this.meta, 'has200')) ||
          (this.isCinema && this.level === 3 && this.seatNum <= 1500) ||
          (this.isGym &&
            this.level === 3 &&
            ((!_.includes(this.meta, 'openAir') && this.seatNum <= 3000) || (_.includes(this.meta, 'openAir') && this.seatNum <= 5000))) ||
          (this.isHall && this.level === 3 && this.seatNum <= 2000)
        // 自喷等级(1：轻危，2：中危1，3：中危2)
        let sprayLevel = 2
        if (
          this.isHouse ||
          this.isOldBuild ||
          this.isKid ||
          (this.level === 3 &&
            (this.isHotel || this.isOffice || this.isComplex || this.isPostal || this.isFinancial || this.isTV || this.isDispatch))
        ) {
          sprayLevel = 1
        } else if (this.isShop && (this.s1 >= 5000 || this.c1 * this.s >= 1000)) {
          sprayLevel = 3
        } else {
          sprayLevel = 2
        }
        const data = {
          h: Number(this.customH) || Number(this.h),
          h1: Number(this.customH1) || Number(this.h1),
          s: Number(this.s),
          v: Number(this.customV) || Number(this.v),
          c: Number(this.c),
          c1: Number(this.c1),
          s1: Number(this.customS1) || Number(this.s1),
          poi: this.poi,
          isParty: _.includes(this.meta, 'isParty'),
          isOffice: this.isOffice,
          isOldBuild: this.isOldBuild,
          isHotel: this.isHotel,
          isPTransport: this.isPTransport,
          isShop: this.isShop,
          isHospital: this.isHospital,
          isComplex: this.isComplex, // 综合建筑
          isEntertainment: this.isEntertainment, // 歌舞娱乐
          isFinancial: this.isFinancial, // 金融
          isSchool: this.isSchool, // 教学建筑
          isHouse: this.isHouse, // 住宅
          isDispatch: this.isDispatch, // 指挥调度，电力调度
          isPostal: this.isPostal, // 邮政电信
          isTV: this.isTV, // 广电
          isReserch: this.isReserch, // 科研
          isAncient: this.isAncient,
          isExhibit: this.isExhibit,
          isMuseum: this.isMuseum,
          isArchive: this.isArchive,
          isBook: this.isBook,
          isKid: this.isKid,
          isWelfare: this.isWelfare,
          isDorm: this.isDorm,
          isMarket: this.isMarket,
          isDining: this.isDining,
          isGym: this.isGym,
          isCinema: this.isCinema,
          isHall: this.isHall,
          isMDD,
          isKW, //幼儿园 儿童福利院
          hasCShop,
          hasCourtyard: _.includes(this.meta, 'hasCourtyard'), // 中庭
          hasAir: _.includes(this.meta, 'hasAir'), // 空调
          fireLevel: this.fireLevel,
          hospitalType: this.hospitalType,
          dispatchType: this.dispatchType,
          ancientLevel: this.ancientLevel, // 文物级别
          bookNum: this.bookNum,
          seatNum: Number(this.seatNum),
          TVType: this.TVType,
          hasBed150: _.includes(this.meta, 'hasBed150'),
          hasEtmt1500: _.includes(this.meta, 'hasEtmt1500'), // 歌舞娱乐人数大于1500
          provincial: _.includes(this.meta, 'provincial'), // 省级
          city: _.includes(this.meta, 'city'), // 地市级及以上
          has500: _.includes(this.meta, 'has500'), // 学校 人数超500
          has200: _.includes(this.meta, 'has200'), // 幼儿园 儿童福利院 人数超500
          has1500: _.includes(this.meta, 'has1500'), // 人数大于1500
          openAir: _.includes(this.meta, 'openAir'), // 露天
          indpt: _.includes(this.meta, 'indpt'), // 独立建造
          hasS1000: _.includes(this.meta, 'hasS1000'), // 24m以上，任一层面积大于1000㎡
          typeId: this.typeId,
          type: this.type,
          name: this.name,
          level: this.level,
          noSpray,
          sprayLevel,
          values: {
            h: Number(this.customH) || Number(this.h),
            h1: Number(this.customH1) || Number(this.h1),
            s: Number(this.s),
            v: Number(this.customV) || Number(this.v),
            c: Number(this.c),
            c1: Number(this.c1),
            s1: Number(this.customS1) || Number(this.s1),
            poi: this.poi,
            meta: this.meta,
            typeId: this.typeId,
            hospitalType: this.hospitalType,
            fireLevel: this.fireLevel,
          },
        }
        this.$ls.set('judge', JSON.stringify(data))
        this.$router.push({ name: 'judge-result' })
      })
    },
    handleSelectResult(item) {
      const data = item[0]
      const { values } = data.body
      this.name = data.body.name
      this._setType(data.body.typeId, data.body.type)
      this.$nextTick(() => {
        this.poi = values.poi
        this.h = values.h
        this.h1 = values.h1
        this.c = values.c
        this.c1 = values.c1
        this.s = values.s
        this.s1 = values.s1
        this.v = values.v
        this.meta = values.meta
        this.typeId = values.typeId
        this.hospitalType = values.hospitalType
        this.fireLevel = values.fireLevel

        this.poiVisible = false
      })
    },
    _setType(val, label) {
      this.type = label
      this.typeId = val
      this.meta = []
      this.fireLevel = ''
      this.hospitalType = ''
      this.dispatchType = ''
      this.ancientLevel = '' // 文物级别
      this.bookNum = '' // 藏书数量
      this.seatNum = '' // 座位数量
      this.TVType = ''
      // 办公建筑
      this.isOffice = this.typeId === 229
      // 老年人设施
      this.isOldBuild = this.typeId === 208
      // 旅馆
      this.isHotel = this.typeId === 215
      // 客运建筑
      this.isPTransport = this.typeId === 219
      // 商店建筑
      this.isShop = this.typeId === 227
      // 医疗建筑
      this.isHospital = this.typeId === 210
      // 综合建筑
      this.isComplex = this.typeId >= 237 && this.typeId <= 241
      // 歌舞娱乐
      this.isEntertainment = this.typeId >= 230 && this.typeId <= 236
      // 金融
      this.isFinancial = this.typeId === 214
      // 教学建筑
      this.isSchool = this.typeId === 224
      // 住宅
      this.isHouse = this.typeId === 109
      // 指挥调度、电力调度
      this.isDispatch = this.typeId === 220 || this.typeId === 221
      // 邮政电信
      this.isPostal = this.typeId === 211 || this.typeId === 212
      // 广电
      this.isTV = this.typeId === 213
      // 科研
      this.isReserch = this.typeId === 242

      this.isAncient = this.typeId === 203 // 文物
      this.isExhibit = this.typeId === 207 // 展览馆
      this.isMuseum = this.typeId === 206 // 博物馆
      this.isArchive = this.typeId === 205 // 档案馆
      this.isBook = this.typeId === 204 // 图书馆
      this.isKid = this.typeId === 223 // 幼儿园
      this.isWelfare = this.typeId === 209 // 儿童福利院
      this.isDorm = this.typeId === 225 // 宿舍
      this.isMarket = this.typeId === 228 // 集市
      this.isDining = this.typeId === 217 || this.typeId === 216 // 餐厅食堂
      this.isGym = this.typeId === 202 // 体育馆
      this.isCinema = this.typeId === 200 // 影剧院
      this.isHall = this.typeId === 201 // 会堂
    },
    _initType(data) {
      return data
      // if (this.isPro) {
      //   return data
      //     .filter(item => {
      //       if (item.children) {
      //         item.children.options = this._initType(item.children.options)
      //       }
      //       if (
      //         _.includes(this.typeIds, item.value) ||
      //         (item.children && item.children.options.length > 0) ||
      //         (item.value >= 243 && item.value <= 252)
      //       ) {
      //         return true
      //       }
      //       return false
      //     })
      //     .map(item => {
      //       item.disabled = item.value >= 243 && item.value <= 252
      //       return item
      //     })
      // } else if (this.isTest) {
      //   return data.map(item => {
      //     if (item.children) {
      //       item.children.options = this._initType(item.children.options)
      //     }
      //     if (item.value >= 243 && item.value <= 252) {
      //       item.disabled = true
      //     }
      //     return item
      //   })
      // } else {
      //   return data.map(item => {
      //     if (item.children) {
      //       item.children.options = this._initType(item.children.options)
      //     }
      //     if (!_.includes(this.typeIds, item.value) && !item.children && !(item.value >= 243 && item.value <= 252)) {
      //       item.disabled = true
      //       item.label += '（企业版专属）'
      //     } else if (item.value >= 243 && item.value <= 252) {
      //       item.disabled = true
      //     }
      //     return item
      //   })
      // }
    },
  },
}
</script>

<style lang="stylus" scoped>
.container
  padding-bottom 100px
  .building-type >>>
    .md-field-item-control
      text-align right
</style>
