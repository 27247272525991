export default {
  name: 'level1',
  label: '一级场所',
  options: [
    {
      value: 1,
      label: '公共建筑',
      children: {
        name: 'level2',
        label: '二级场所',
        options: [
          {
            value: 100,
            label: '文体艺术',
            children: {
              name: 'level3',
              label: '三级场所',
              options: [
                {
                  value: 200,
                  label: '影剧院（电影院、剧场）',
                },
                {
                  value: 201,
                  label: '会礼堂（会议中心）',
                },
                {
                  value: 202,
                  label: '体育馆',
                },
                {
                  value: 203,
                  label: '文物古建筑',
                },
                {
                  value: 204,
                  label: '图书馆',
                },
                {
                  value: 205,
                  label: '档案馆',
                },
                {
                  value: 206,
                  label: '博物馆',
                },
                {
                  value: 207,
                  label: '展览建筑',
                },
              ],
            },
          },
          {
            value: 101,
            label: '卫生福利',
            children: {
              name: 'level3',
              label: '三级场所',
              options: [
                {
                  value: 208,
                  label: '老年人照料设施',
                },
                {
                  value: 209,
                  label: '儿童福利院',
                },
                {
                  value: 210,
                  label: '医疗建筑（门诊楼、住院楼、病房楼）',
                },
              ],
            },
          },
          {
            value: 102,
            label: '邮电金融',
            children: {
              name: 'level3',
              label: '三级场所',
              options: [
                {
                  value: 211,
                  label: '邮政楼',
                },
                {
                  value: 212,
                  label: '电信楼',
                },
                {
                  value: 213,
                  label: '广电楼',
                },
                {
                  value: 214,
                  label: '财贸金融建筑（证券、银行、保险）',
                },
              ],
            },
          },
          {
            value: 103,
            label: '住宿餐饮',
            children: {
              name: 'level3',
              label: '三级场所',
              options: [
                {
                  value: 215,
                  label: '旅馆建筑（酒店、宾馆、饭店、度假村、酒店式公寓、出租型公寓、无治疗功能的疗养院）',
                },
                {
                  value: 216,
                  label: '餐馆',
                },
                {
                  value: 217,
                  label: '食堂',
                },
                {
                  value: 218,
                  label: '茶楼',
                },
              ],
            },
          },
          {
            value: 104,
            label: '交通调度',
            children: {
              name: 'level3',
              label: '三级场所',
              options: [
                {
                  value: 219,
                  label: '客运建筑（车站、码头、机场）',
                },
                {
                  value: 220,
                  label: '指挥调度楼',
                },
                {
                  value: 221,
                  label: '电力调度楼',
                },
              ],
            },
          },
          {
            value: 105,
            label: '教育科研',
            children: {
              name: 'level3',
              label: '三级场所',
              options: [
                // {
                //   value: 222,
                //   label: '托儿所',
                // },
                {
                  value: 223,
                  label: '托儿所、幼儿园',
                },
                {
                  value: 224,
                  label: '教学建筑（教学楼、实验楼）',
                },
                {
                  value: 225,
                  label: '宿舍建筑（学生公寓、教职工公寓、集体宿舍）',
                },
                {
                  value: 226,
                  label: '学校图书馆',
                },
                {
                  value: 242,
                  label: '科研楼',
                },
              ],
            },
          },
          {
            value: 106,
            label: '商店办公',
            children: {
              name: 'level3',
              label: '三级场所',
              options: [
                {
                  value: 227,
                  label: '商店建筑（商场、购物中心、超市）',
                },
                {
                  value: 228,
                  label: '集贸市场（菜市场）',
                },
                {
                  value: 229,
                  label: '办公建筑（写字楼、办公楼）',
                },
              ],
            },
          },
          {
            value: 107,
            label: '歌舞娱乐',
            children: {
              name: 'level3',
              label: '三级场所',
              options: [
                {
                  value: 230,
                  label: '歌舞厅',
                },
                {
                  value: 231,
                  label: '录像厅',
                },
                {
                  value: 232,
                  label: '夜总会',
                },
                {
                  value: 233,
                  label: '卡拉OK厅',
                },
                {
                  value: 234,
                  label: '游艺厅',
                },
                {
                  value: 235,
                  label: '桑拿室',
                },
                {
                  value: 236,
                  label: '网吧',
                },
              ],
            },
          },
          {
            value: 108,
            label: '综合建筑（内设）',
            children: {
              name: 'level3',
              label: '三级场所',
              options: [
                {
                  value: 237,
                  label: '营业厅',
                },
                {
                  value: 238,
                  label: '展览厅',
                },
                {
                  value: 239,
                  label: '会议厅',
                },
                {
                  value: 240,
                  label: '多功能厅',
                },
                {
                  value: 241,
                  label: '观众厅',
                },
              ],
            },
          },
        ],
      },
    },
    {
      value: 2,
      label: '住宅建筑',
      children: {
        name: 'level2',
        label: '二级场所',
        options: [
          {
            value: 109,
            label: '住宅建筑（底部带网点）',
          },
        ],
      },
    },
    {
      value: 3,
      label: '工业建筑',
      children: {
        name: 'level2',
        label: '二级场所',
        options: [
          {
            value: 110,
            label: '厂房',
            children: {
              name: 'level3',
              label: '三级场所',
              options: [
                {
                  value: 243,
                  label: '甲类',
                },
                {
                  value: 244,
                  label: '乙类',
                },
                {
                  value: 245,
                  label: '丙类',
                },
                {
                  value: 246,
                  label: '丁类',
                },
                {
                  value: 247,
                  label: '戊类',
                },
              ],
            },
          },
          {
            value: 111,
            label: '仓库',
            children: {
              name: 'level3',
              label: '三级场所',
              options: [
                {
                  value: 248,
                  label: '甲类',
                },
                {
                  value: 249,
                  label: '乙类',
                },
                {
                  value: 250,
                  label: '丙类',
                },
                {
                  value: 251,
                  label: '丁类',
                },
                {
                  value: 252,
                  label: '戊类',
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
