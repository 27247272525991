import remoteLoad from '@/utils/remoteLoad'

export default {
  data() {
    return {
      AMapUI: null,
      AMap: null,
    }
  },
  async mounted() {
    // 已载入高德地图API，则直接初始化地图
    if (window.AMap && window.AMapUI) {
      this.initMap()
      // 未载入高德地图API，则先载入API再初始化
    } else {
      await remoteLoad('https://webapi.amap.com/maps?v=1.4.15&key=013d14164badc352fce81caed3cdab91')
      await remoteLoad('http://webapi.amap.com/ui/1.0/main.js')
      this.initMap()
    }
  },
}
